import { apiCall } from "@/lib/api";

export default {
  strict: true,
  state: {
    user: {
      id: "",
      username: "",
      fist_name: "",
      last_name: "",
      email: "",
      is_superuser: false,
      is_twitter_power_user: false,
      is_shodan_power_user: false,
      report_editor_draft: "",
      app_settings: {},
      two_factor_enabled: false,
      notify_emails: false,
      notify_desktop: false,
      notify_terms: "off",
      notify_sources: "off",
      notify_hashtags: "off",
      has_tlp_green_access: false,
      has_tlp_white_access: false,
      has_tlp_amber_access: false,
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async getCurrentUser(context) {
      const response = await apiCall("GET", "/current-user/");
      response.notify_desktop =
        (window.localStorage.getItem("notify-desktop") || "false") === "true";
      context.commit("setUser", response);
    },
    async updateReportEditorDraft(context, draft) {
      const response = await apiCall(
        "POST",
        "/current-user/",
        {},
        {
          report_editor_draft: draft,
        }
      );
      context.commit("setUser", response);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    userNeeds2FA(state) {
      return (
        state.user.app_settings.enforce_2fa && !state.user.two_factor_enabled
      );
    },
    isAuth(state) {
      return !!state.user.username;
    },
  },
};
