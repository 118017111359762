import Vue from "vue";
import VueRouter from "vue-router";
import { apiBase } from "@/lib/api";
import store from "@/store";

const Search = () => import(/* webpackChunkName: "search" */ "@/views/Search");
const TrendingTerms = () =>
  import(/* webpackChunkName: "trending" */ "@/views/TrendingTerms");
const ReportEditor = () =>
  import(/* webpackChunkName: "reportEditor" */ "@/views/ReportEditor");
const ManageSources = () =>
  import(/* webpackChunkName: "manageSources" */ "@/views/Admin/ManageSources");
const KnowledgeGraph = () =>
  import(/* webpackChunkName: "knowledgeGraph" */ "@/views/KnowledgeGraph");
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard");
const SuggestSource = () =>
  import(/* webpackChunkName: "suggestSource" */ "@/views/SuggestSource");
const CVEExplorer = () =>
  import(/* webpackChunkName: "cveExplorer" */ "@/views/CVEExplorer");
const CVEMap = () => import(/* webpackChunkName: "cveMap" */ "@/views/CVEMap");
const Notifications = () =>
  import(/* webpackChunkName: "notifications" */ "@/views/Notifications");
const CollabAndGroupsView = () =>
  import(
    /* webpackChunkName: "collaboratorView" */ "@/views/CollabAndGroupsView"
  );
const SentAndReceivedShareView = () =>
  import(/* webpackChunkName: "share" */ "@/views/SentAndReceivedShareView");
const SavedSearchesView = () =>
  import(/* webpackChunkName: "savedSearches" */ "@/views/SavedSearchesView");
const SnapshotResultsView = () =>
  import(/* webpackChunkName: "savedSearches" */ "@/views/SnapshotResultsView");

// Admin views
const ImportSources = () =>
  import(/* webpackChunkName: "importSources" */ "@/views/Admin/ImportSources");
const AuditLog = () =>
  import(/* webpackChunkName: "auditLog" */ "@/views/Admin/AuditLog");

// Account views
const AccountSettings = () =>
  import(
    /* webpackChunkName: "accountSettings" */ "@/views/Account/AccountSettings"
  );

// Login Views
const LoginPage = () =>
  import(/* webpackChunkName: "loginPage" */ "@/views/Account/LoginPage");
const PasswordReset = () =>
  import(
    /* webpackChunkName: "passwordReset" */ "@/views/Account/PasswordReset"
  );
const PasswordResetDone = () =>
  import(
    /* webpackChunkName: "passwordResetDone" */ "@/views/Account/PasswordResetDone"
  );
const PasswordResetConfirm = () =>
  import(
    /* webpackChunkName: "passwordResetConfirm" */ "@/views/Account/PasswordResetConfirm"
  );
const PasswordResetComplete = () =>
  import(
    /* webpackChunkName: "passwordResetComplete" */ "@/views/Account/PasswordResetComplete"
  );
const LogoutPage = () =>
  import(/* webpackChunkName: "logoutPage" */ "@/views/Account/LogoutPage");

Vue.use(VueRouter);

const routes = [
  {
    path: "/my-account/settings",
    name: "account-settings",
    meta: { displayName: "Account Settings", requireSuperUser: false },
    component: AccountSettings,
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: { displayName: "", requireSuperUser: false },
    alias: ["/"],
  },
  {
    path: "/search/report",
    name: "report-editor",
    component: ReportEditor,
    meta: { displayName: "Report Editor", requireSuperUser: false },
  },
  {
    path: "/trending",
    name: "trending",
    component: TrendingTerms,
    meta: { displayName: "Trending Terms", requireSuperUser: false },
  },
  {
    path: "/suggest-source",
    name: "suggest-sources",
    component: SuggestSource,
    props: {
      source: {},
    },
    meta: { displayName: "Suggest Source", requireSuperUser: false },
  },
  {
    path: "/knowledge-graph",
    name: "knowledge-graph",
    component: KnowledgeGraph,
    meta: { displayName: "Knowledge Graph", requireSuperUser: false },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { displayName: "Dashboard", requireSuperUser: false },
  },
  {
    path: "/cve-explorer",
    name: "cve-explorer",
    component: CVEExplorer,
    meta: { displayName: "CVE Explorer", requireSuperUser: false },
  },
  {
    path: "/cve-map",
    name: "cve-map",
    component: CVEMap,
    meta: { displayName: "Shodan Map", requireSuperUser: false },
  },
  {
    path: "/collaborators",
    name: "collaborators",
    component: CollabAndGroupsView,
    meta: { displayName: "Collaborators", requireSuperUser: false },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: { displayName: "Notifications", requireSuperUser: false },
  },
  {
    path: "/share",
    name: "share",
    component: SentAndReceivedShareView,
    meta: { displayName: "Share", requireSuperUser: false },
  },
  {
    path: "/saved-searches",
    name: "saved-searches",
    component: SavedSearchesView,
    meta: { displayName: "Saved Searches", requireSuperUser: false },
  },
  {
    path: "/saved-searches-results",
    name: "saved-searches-results",
    component: SnapshotResultsView,
    meta: { displayName: "Compare Saved Searches", requireSuperUser: false },
  },
  {
    path: "/account/login",
    meta: { displayName: "Login", requireSuperUser: false },
    name: "account-login",
    components: {
      login: LoginPage,
    },
  },
  {
    path: "/account/login/password-reset",
    name: "account-password-reset",
    components: {
      login: PasswordReset,
    },
  },
  {
    path: "/account/login/password-reset-done",
    name: "account-password-reset-done",
    components: {
      login: PasswordResetDone,
    },
  },
  {
    path: "/account/password-reset-confirm",
    name: "account-password-reset-confirm",
    components: {
      login: PasswordResetConfirm,
    },
  },
  {
    path: "/account/password-reset-complete",
    name: "account-password-reset-complete",
    components: {
      login: PasswordResetComplete,
    },
  },
  {
    path: "/account/logout",
    meta: { displayName: "Logout", requireSuperUser: false },
    name: "account-logout",
    components: {
      login: LogoutPage,
    },
  },
  // Admin routes
  {
    path: "/s/import-sources",
    name: "import-source",
    component: ImportSources,
    meta: { displayName: "Import Sources", requireSuperUser: true },
  },
  {
    path: "/s/audit-log",
    name: "audit-log",
    component: AuditLog,
    meta: { displayName: "Knowledge Graph Audit Log", requireSuperUser: true },
  },
  {
    path: "/s/sources",
    name: "manage-sources",
    component: ManageSources,
    meta: { displayName: "Manage Sources", requireSuperUser: true },
  },
  // Backend routes
  {
    path: "/_logout",
    name: "logout",
    meta: { displayName: "Logout", requireSuperUser: false },
    beforeEnter() {
      window.location = `${apiBase}/admin/logout`;
    },
  },
  {
    path: "/_user-manual",
    name: "user-manual",
    meta: { displayName: "User Manual", requireSuperUser: false },
    beforeEnter() {
      window.location = `${apiBase}/docs/user/index.html`;
    },
  },
  {
    path: "/_users",
    name: "manage-users",
    meta: { displayName: "Manage Users", requireSuperUser: true },
    beforeEnter() {
      window.location = `${apiBase}/admin/core/user`;
    },
  },
  {
    path: "/_proxies",
    name: "manage-proxies",
    meta: { displayName: "Manage Proxies", requireSuperUser: true },
    beforeEnter() {
      window.location = `${apiBase}/admin/core/socksproxy/`;
    },
  },
  {
    path: "/_manage-tlp",
    name: "manage-tlp",
    meta: { displayName: "Manage TLP Documents", requireSuperUser: true },
    beforeEnter() {
      window.location = `${apiBase}/admin/core/pdfupload/`;
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const userNeeds2FA = store.getters.userNeeds2FA;
  if (to.name !== "account-settings" && userNeeds2FA) {
    next({ name: "account-settings" });
  } else {
    next();
  }
});

export default router;
