<template>
  <div>
    <v-app-bar app dark color="primary" fixed dense>
      <v-app-bar-nav-icon v-if="!userNeeds2FA" @click="drawer = !drawer" />
      <v-avatar tile class="mr-2" size="44">
        <v-img
          src="/logo-small-inverted.png"
          alt="ENISA"
          contain
          :title="appVersion"
        />
      </v-avatar>
      <v-toolbar-title>
        <span>OpenSSAM</span>
        <span v-if="$route.meta.displayName">
          &middot;
          {{ $route.meta.displayName }}
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="!userNeeds2FA">
        <v-tooltip
          v-for="item in menuItemsForUser"
          :key="`quick-${item.name}`"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              :to="{ name: item.name }"
              class="no-active"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.display }}</span>
        </v-tooltip>

        <v-badge
          :content="unreadNotificationsCount"
          :value="unreadNotificationsCount"
          dot
          bottom
          overlap
          color="red"
          offset-y="22"
          offset-x="22"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                :to="{ name: 'notifications' }"
                class="no-active"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-bell</v-icon>
              </v-btn>
            </template>
            <span>{{ unreadNotificationsCount }} unread notifications</span>
          </v-tooltip>
        </v-badge>

        <v-badge
          v-if="!$vuetify.breakpoint.xs"
          :content="selectedArticleCount"
          :value="selectedArticleCount"
          bottom
          overlap
          color="success"
          offset-y="15"
          offset-x="15"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                exact
                :to="{ name: 'report-editor' }"
                color="secondary"
                v-bind="attrs"
                v-on="on"
              >
                Report Editor
                <v-icon class="pl-2">chrome_reader_mode</v-icon>
              </v-btn>
            </template>
            <span>{{ selectedArticleCount }} article(s) selected</span>
          </v-tooltip>
        </v-badge>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <sidebar />
      <template #append>
        <div class="d-flex justify-center">
          <v-switch
            v-model="$vuetify.theme.dark"
            dense
            append-icon="brightness_4"
            color="accent"
          />
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import { mapGetters } from "vuex";

export default {
  name: "Toolbar",
  components: { Sidebar },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      drawer: false,
      quickMenuItems: [
        {
          name: "search",
          display: "Search",
          icon: "search",
        },
        {
          name: "dashboard",
          display: "Dashboard",
          icon: "dashboard",
        },
        {
          name: "trending",
          display: "Trending Terms",
          icon: "insights",
        },
        {
          name: "collaborators",
          display: "Collaborators",
          icon: "people",
        },
        {
          name: "share",
          display: "Share",
          icon: "share",
        },
        {
          name: "saved-searches",
          display: "Saved Searches",
          icon: "mdi-bookmark",
        },
        {
          name: "suggest-sources",
          display: "Suggest Source",
          icon: "library_add",
          regularUserOnly: true,
        },
        {
          name: "manage-sources",
          display: "Manage sources",
          icon: "mdi-newspaper-variant-multiple",
          superUserOnly: true,
        },
        {
          name: "knowledge-graph",
          display: "Knowledge Graph",
          icon: "mdi-graph",
        },
        {
          name: "cve-explorer",
          display: "CVE Explorer",
          icon: "mdi-shield-bug",
        },
        {
          name: "cve-map",
          display: "Shodan Map",
          icon: "mdi-map-search",
          shodanPowerUserOnly: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "selectedArticleCount",
      "user",
      "unreadNotificationsCount",
      "userNeeds2FA",
    ]),
    menuItemsForUser() {
      const filteredMenuItems = this.quickMenuItems.filter(
        (item) =>
          (!item.superUserOnly || this.user.is_superuser) &&
          (!item.regularUserOnly || !this.user.is_superuser) &&
          (!item.shodanPowerUserOnly || this.user.is_shodan_power_user)
      );
      if (this.$vuetify.breakpoint.xs) {
        return filteredMenuItems.filter((item) => item.displayOnMobile);
      }
      return filteredMenuItems;
    },
  },
};
</script>

<style scoped lang="scss"></style>
