<template>
  <v-app id="app">
    <div v-if="!isAuth || $route.name === 'account-logout'">
      <global-loader />
      <div v-if="loaded">
        <login />
      </div>
    </div>

    <div v-else>
      <global-loader />
      <notifications
        position="top center"
        classes="opencsam-notification"
        ignore-duplicates
        :max="2"
        width="450"
      />

      <toolbar />
      <v-main v-if="loaded && !error" class="lighten-5 justify-center">
        <router-view />
      </v-main>
      <v-main v-else class="lighten-5 justify-center align-center">
        <v-card v-if="!loaded" class="ma-5">
          <v-card-title> Loading OpenSSAM </v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular
              indeterminate
              size="100"
              color="primary"
              class="my-10"
            />
          </v-card-text>
        </v-card>

        <v-card v-if="error" class="ma-5">
          <v-card-title class="elevation-8 error">
            <v-icon left>error</v-icon>
            {{ error }}
          </v-card-title>
        </v-card>
      </v-main>

      <v-footer color="transparent" app absolute>
        <div class="d-flex align-center justify-end flex-grow-1">
          <span>ENISA is an agency of the European Union</span>
          <img src="@/assets/img/eu-flag.png" alt="EU-flag" class="ml-2" />
        </div>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
import Toolbar from "@/components/Toolbar";
import { mapActions, mapGetters } from "vuex";
import GlobalLoader from "@/components/GlobalLoader";
import Login from "@/views/Account/Login";
import { wsBase } from "@/lib/api";
import { createDesktopNotification } from "@/lib/utils";

export default {
  components: { GlobalLoader, Toolbar, Login },
  data() {
    return {
      loaded: false,
      error: null,
      wsNotifications: null,
    };
  },

  computed: {
    ...mapGetters(["user", "isAuth", "appSettings", "userNeeds2FA"]),
  },
  watch: {
    "$vuetify.theme.dark"(dark) {
      this.setLocalStorage("theme", dark ? "dark" : "light");
    },
    $route() {
      // Log a new view manually when the route changes.
      if (!window._paq) return;
      window._paq.push(["trackPageView"]);
    },
  },
  mounted() {
    this.initTheme();
    this.initApp();
  },
  methods: {
    ...mapActions(["initStore", "reloadNotifications", "getAppSettings"]),
    initTheme() {
      this.$vuetify.theme.dark = this.getLocalStorage("theme") === "dark";
    },
    async maybeRedirectUser() {
      if (this.$route.name !== "account-settings" && this.userNeeds2FA)
        await this.$router.push({ name: "account-settings" });
    },
    async initApp() {
      try {
        await this.getAppSettings();
        await this.initSentry();

        await this.initStore();
        await this.setSentryUser();

        await this.maybeRedirectUser();
        this.initWS();
        if ("superuser_required" in this.$route.query) {
          this.$notify({
            type: "error",
            text: "No permission to access the requested page.",
          });
          delete this.$route.query.superuser_required;
        }
        this.loaded = true;
      } catch (e) {
        switch (e.statusCode) {
          case 403:
          case 401:
            this.loaded = true;
            if (this.$route.name === "account-password-reset-confirm") {
              return;
            }

            this.$router.replace({
              name: "account-login",
              query: {
                auto_login: true,
                next:
                  this.$route.name === "account-login" ? "/" : this.$route.path,
              },
            });
            break;
          default:
            this.loaded = true;
            if (!this.isAuth) {
              // If the app crashed before checking authentication we
              // cannot recover. So just show the error message instead.
              this.error =
                "Unknown fatal error while loading OpenSSAM, please try again later.";
            }
            throw e;
        }
      }

      await this.initAnalytics();
    },
    initWS() {
      if (!this.isAuth) return;

      // First off close any WebSocket that might have been opened beforehand,
      // this makes sure that the function can be safely called multiple times,
      // without duplicating the notifications.
      if (this.wsNotifications) {
        this.wsNotifications.close();
        this.wsNotifications = null;
      }

      this.wsNotifications = new WebSocket(`${wsBase}/user/notifications/`);
      this.wsNotifications.addEventListener("error", (error) => {
        // Nothing we can do here, but log the error. Pushing an error
        // message to the user, won't be of any help since it's not something
        // they can fix, and it might spam them with numerous errors.
        console.error("Notification error", error);
      });

      // Reload the count for the icon badge and tooltip, and then
      // send a desktop notification to the user if that feature is enabled.
      this.wsNotifications.addEventListener("message", (msg) => {
        this.$store.dispatch("reloadNotifications");
        if (this.user.notify_desktop) {
          createDesktopNotification(JSON.parse(msg.data));
        }
      });
    },
    async initAnalytics() {
      if (!this.isAuth) return;

      const settings = this.user.app_settings;

      if (
        !settings.analytics_server ||
        !settings.analytics_site_id ||
        window._paq
      )
        return;

      const _paq = (window._paq = window._paq || []);
      _paq.push(["setUserId", this.user.id]);
      _paq.push(["trackPageView"]);
      _paq.push(["setTrackerUrl", settings.analytics_server + "matomo.php"]);
      _paq.push(["setSiteId", settings.analytics_site_id]);
      const d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = settings.analytics_server + "matomo.js";
      s.parentNode.insertBefore(g, s);
    },
    async initSentry() {
      if (!this.appSettings.sentry_dsn) return;

      // Lazy import in a separate chunk, so it does not get downloaded
      // in environments where this is disabled.
      const sentry = await import(
        /* webpackChunkName: "sentry-reporting" */ "@sentry/browser"
      );
      const sentryTracing = await import(
        /* webpackChunkName: "sentry-reporting" */ "@sentry/tracing"
      );

      sentry.init({
        dsn: this.appSettings.sentry_dsn,
        integrations: [new sentryTracing.BrowserTracing()],
        environment: this.appSettings.sentry_env,
        release: this.appSettings.sentry_release,
        traces_sample_rate: 1.0,
      });
    },
    async setSentryUser() {
      if (!this.appSettings.sentry_dsn || !this.user.username) return;

      const sentry = await import(
        /* webpackChunkName: "sentry-reporting" */ "@sentry/browser"
      );
      sentry.setUser({
        id: this.user.id,
        username: this.user.username,
      });
    },
  },
};
</script>

<style lang="scss">
@import "styles/index";
@import "styles/notifications";
</style>
